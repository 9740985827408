import { useState, useEffect } from "react";
import axios from "axios";
import { parseString } from "xml2js";

export const useFetchNews = (url, type) => {
	const [loading, setLoading] = useState(false);
	const [news, setNews] = useState([]);

	useEffect(() => {
		setLoading(true);

		axios(url)
			.then(function (response) {
				// console.log("axios response", response);
				parseString(response.data, null, function (err, result) {
					// console.log("axios parseString B", result, err);
					const newsItems = result.rss.channel[0].item;
					setLoading(false);
					setNews(newsItems);
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [url]);

	return { loading, news };
};
