import styled from "styled-components";
import { colors, mediaQueryMax } from "../../../assets/styles/vars";
import { BoxPlaceImgWrapper } from "../../BoxPlaceImg/styled";

export const GridWrapper = styled.section`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 30px;
	position: relative;
`;

export const GridTitle = styled.div`
	order: 0;
	max-width: 50%;
	h3 {
		color: ${(props) =>
			props.layout === "dark" ? colors.white : colors.black00};
		margin-bottom: 10px;
	}
	p {
		color: ${(props) =>
			props.layout === "dark" ? colors.white : colors.black00};
		margin: 0;
	}
	@media ${mediaQueryMax.lg} {
		flex: 0 0 100%;
		max-width: 100%;
	}
`;

export const GridBtns = styled.div`
	order: 1;
	max-width: 50%;
	@media ${mediaQueryMax.lg} {
		order: 2;
		justify-content: space-between;
		flex: 0 0 100%;
		max-width: 100%;
	}
`;

export const GridItems = styled.div`
	order: 2;
  flex: 0 0 100%;
  max-width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 20px;
	grid-template-areas: "areaBig areaSmall1" "areaBig areaSmall2";
	width: 100%;

	${BoxPlaceImgWrapper}{
		&:nth-child(1) {
			grid-area: areaBig;
			height: 600px;
		}

		&:nth-child(2) {
			grid-area: areaSmall1;
			height: 290px;
		}

		&:nth-child(3) {
			grid-area: areaSmall2;
			height: 290px;
		}
	}
	.swiper {
		overflow: visible;
		.swiper-wrapper {
			.swiper-slide {
				height: auto;
			}
		}
	}
	@media ${mediaQueryMax.lg} {
		order: 1;
	}
	@media ${mediaQueryMax.md}{
    margin-bottom: 30px;
		display: block;
    ${BoxPlaceImgWrapper}{
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
				height: 350px;
      }
    }
`;
