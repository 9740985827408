import React from "react";
import { CentrosListWrapper, ListWrapper, EmptyList } from "./styled";
import { connect } from "react-redux";
import CentroListItem from "../CentroListItem";
import { CustomLink } from "../../../assets/styles/globalCommon";
import CentroListItemSkeleton from "../CentroListItem/CentroListItemSkeleton";

const CentrosList = (props) => {
	const {
		layout,
		title,
		buttonText,
		buttonUrl,
		emptyInfo,
		items,
		itemsLoadingSuccess,
		units,
		authResolved,
		loggedIn,
	} = props;

	const renderSkeleton = () => {
		const tempArray = [...Array(6).keys()];
		return tempArray.map((_item, index) => {
			return <CentroListItemSkeleton key={index} />;
		});
	};

	const renderEmpty = () => {
		if (emptyInfo) {
			return (
				<EmptyList>
					{emptyInfo.title && <h3>{emptyInfo.title}</h3>}
					{emptyInfo.text && <div className="text">{emptyInfo.text}</div>}
				</EmptyList>
			);
		} else {
			return null;
		}
	};

	const renderItems = () => {
		return items
			.filter((item, index) => index < 6)
			.map((item, index) => {
				return <CentroListItem centro={item} units={units} key={index} />;
			});
	};

	const renderContentList = () => {
		if (!authResolved) {
			return renderSkeleton();
		} else {
			if (loggedIn) {
				if (items.length > 0) {
					return !itemsLoadingSuccess ? renderSkeleton() : renderItems();
				} else {
					return !itemsLoadingSuccess ? renderSkeleton() : renderEmpty();
				}
			} else {
				return renderEmpty();
			}
		}
	};

	return (
		<CentrosListWrapper layout={layout}>
			{title && <h4>{title}</h4>}
			{buttonText && buttonUrl && (
				<CustomLink
					to={buttonUrl}
					className={`ghost withUnderline ${
						layout === "dark" && "secondary"
					}`}
				>
					<p>{buttonText}</p>
				</CustomLink>
			)}

			<ListWrapper>{renderContentList()}</ListWrapper>
		</CentrosListWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		units: state.app.config.units,
		authResolved: state.auth.resolved,
		loggedIn: state.auth.loggedIn,
	};
};

export default connect(mapStateToProps)(CentrosList);
