import React, { useState, useEffect } from "react";
import { GridWrapper, GridBtns, GridTitle, GridItems } from "./styled";
import { CustomLink } from "../../../assets/styles/globalCommon";
import BoxPlaceImg from "../../BoxPlaceImg";
import { sizesValues } from "../../../assets/styles/vars";
import { Swiper, SwiperSlide } from "swiper/react";

const Grid = (props) => {
	const { layout, items, title, text, buttonText, buttonUrl } = props;
	const [isSlider, setIsSlider] = useState(false);

	useEffect(() => {
		const resizeHandler = () => {
			if (window.innerWidth <= sizesValues.md) {
				if (!isSlider) setIsSlider(true);
			} else {
				if (isSlider) setIsSlider(false);
			}
		};

		resizeHandler();
		window.addEventListener("resize", resizeHandler);

		return () => {
			window.removeEventListener("resize", resizeHandler);
		};
	}, [isSlider]);

	const renderItem = (lugar, index) => {
		return (
			<BoxPlaceImg
				key={index}
				imgSrc={
					lugar.imagen_small && lugar.imagen_small.url
						? lugar.imagen_small.url
						: null
				}
				title={lugar.title}
				description={lugar.subtitle}
				url={lugar.name}
				link={`${lugar.centros.length} estaciones`}
			/>
		);
	};

	return (
		<GridWrapper>
			<GridTitle layout={layout}>
				{title && <h3>{title}</h3>}
				{text && <p dangerouslySetInnerHTML={{ __html: text }} />}
			</GridTitle>
			<GridBtns>
				{buttonText && buttonUrl && (
					<CustomLink
						to={buttonUrl}
						className={`ghost withUnderline ${
							layout === "dark" && "secondary"
						}`}
					>
						<p>{buttonText}</p>
					</CustomLink>
				)}
			</GridBtns>

			<GridItems>
				{isSlider ? (
					<Swiper
						slidesPerView={1.25}
						spaceBetween={15}
						breakpoints={{
							769: {
								slidesPerView: 2,
								spaceBetween: 30,
							},
							1025: {
								slidesPerView: 4,
							},
						}}
					>
						{items?.map((lugar, index) => {
							return (
								<SwiperSlide key={index}>
									{renderItem(lugar, index)}
								</SwiperSlide>
							);
						})}
					</Swiper>
				) : (
					items?.map((lugar, index) => {
						return renderItem(lugar, index);
					})
				)}
			</GridItems>
		</GridWrapper>
	);
};

export default Grid;
