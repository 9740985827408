import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styles from "../assets/css/index.module.scss";
import { connect } from "react-redux";
import Slider from "../components/Common/Slider";
import { snowGods } from "../data/global-data";
import Grid from "../components/Common/Grid";
import {
	PageContent,
	PageContentWrapper,
	StyledBgImage,
} from "../assets/styles/pageCommon";
import { CustomButton, CustomLink } from "../assets/styles/globalCommon";
import CentrosList from "../components/Common/CentrosList";
import LocationSearchFull from "../components/Common/LocationSearchFull";
import {
	MainHomeWrapper,
	MapStationWrapper,
} from "../assets/styles/pages/pageHome";
import { Link } from "gatsby-plugin-intl";
import MessageBox from "../components/Common/MessageBox";
import { Sidetab } from "@typeform/embed-react";
import { colors } from "../assets/styles/vars";
import { appModal } from "../redux/app/actions";
import { useFetchNews } from "../hooks/useFetchNews";

const IndexPage = (props) => {
	const {
		authResolved,
		loggedIn,
		bootLoaded,
		ads,
		lugaresHome,
		favoritos,
		favloadingSuccess,
		isMobile768,
		appModal,
	} = props;

	const renderFavText = () => {
		if (authResolved && loggedIn) {
			return (
				<p>
					Acelera tus búsquedas.
					<br /> Con solo clicar el ícono 💚 tendrás aquí todas tus
					estaciones favoritas.
				</p>
			);
		} else {
			return (
				<p>
					<CustomButton
						className="ghost withUnderline secondary"
						onClick={() => appModal("popuplogin", { campaign: "signup" })}
					>
						<p>Regístrate gratis</p>
					</CustomButton>{" "}
					y acelera tus búsquedas.
					<br /> Con solo clicar el ícono 💚 tendrás aquí todas tus
					estaciones favoritas.
				</p>
			);
		}
	};

	const { news } = useFetchNews(
		"https://api.freshsnow.pro/public/ldn-rss.php",
		"xml",
	);

	return (
		<Layout isHome={true}>
			<Seo title="Home" />

			<section className={styles.home}>
				<MainHomeWrapper>
					{!isMobile768 && (
						<Sidetab
							className="suggestionsBtn"
							id="JfY52MtT"
							buttonText="Sugerencias"
							buttonColor={colors.bluegreen}
							customIcon='<svg width="24" height="24" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25 50C28.415 50 31.6258 49.3464 34.6324 48.0392C37.6552 46.732 40.3186 44.9265 42.6226 42.6226C44.9265 40.3186 46.732 37.6634 48.0392 34.6569C49.3464 31.634 50 28.415 50 25C50 21.585 49.3464 18.3742 48.0392 15.3676C46.732 12.3448 44.9265 9.68137 42.6226 7.37745C40.3186 5.07353 37.6552 3.26797 34.6324 1.96078C31.6095 0.653595 28.3905 0 24.9755 0C21.5605 0 18.3415 0.653595 15.3186 1.96078C12.3121 3.26797 9.65686 5.07353 7.35294 7.37745C5.06536 9.68137 3.26797 12.3448 1.96078 15.3676C0.653595 18.3742 0 21.585 0 25C0 28.415 0.653595 31.634 1.96078 34.6569C3.26797 37.6634 5.07353 40.3186 7.37745 42.6226C9.68137 44.9265 12.3366 46.732 15.3431 48.0392C18.366 49.3464 21.585 50 25 50ZM13.0392 25.0245C13.0392 24.3873 13.2435 23.8725 13.652 23.4804C14.0605 23.0719 14.5833 22.8676 15.2206 22.8676H22.8922V15.1961C22.8922 14.5588 23.0801 14.0359 23.4559 13.6275C23.848 13.219 24.3546 13.0147 24.9755 13.0147C25.6127 13.0147 26.1275 13.219 26.5196 13.6275C26.9281 14.0359 27.1324 14.5588 27.1324 15.1961V22.8676H34.8284C35.4657 22.8676 35.9804 23.0719 36.3726 23.4804C36.781 23.8725 36.9853 24.3873 36.9853 25.0245C36.9853 25.6454 36.781 26.152 36.3726 26.5441C35.9641 26.9199 35.4493 27.1078 34.8284 27.1078H27.1324V34.8039C27.1324 35.4412 26.9281 35.9641 26.5196 36.3726C26.1275 36.7647 25.6127 36.9608 24.9755 36.9608C24.3546 36.9608 23.848 36.7647 23.4559 36.3726C23.0801 35.9641 22.8922 35.4412 22.8922 34.8039V27.1078H15.2206C14.5833 27.1078 14.0605 26.9199 13.652 26.5441C13.2435 26.152 13.0392 25.6454 13.0392 25.0245Z" fill="black"/></svg>'
						/>
					)}
					<PageContentWrapper layout="light">
						<StyledBgImage
							bgImageDesktop="/home/bg/main-banner.jpg"
							bgImageMobile="/home/bg/main-banner-mobile.jpg"
						/>
						<PageContent width="1150px" paddingTop="0" paddingBottom="0">
							<h2>Encuentra aquí la mejor nieve cerca de ti.</h2>
							<LocationSearchFull />
						</PageContent>
					</PageContentWrapper>
				</MainHomeWrapper>

				<PageContentWrapper layout="dark">
					<PageContent width="1050px" paddingBottom="0">
						<CentrosList
							layout="dark"
							title="Estaciones favoritas"
							buttonText="Ver Todos"
							buttonUrl="/favs"
							items={favoritos}
							itemsLoadingSuccess={favloadingSuccess}
							emptyInfo={{
								title: "Aún no tienes estaciones favoritas",
								text: renderFavText(),
							}}
						/>
					</PageContent>
				</PageContentWrapper>

				<PageContentWrapper layout="dark" className="mapStationContent">
					<PageContent width="1050px" paddingTop="0" paddingBottom="0">
						<MapStationWrapper>
							<div className="left">
								<h4>Cobertura</h4>
								<p>
									Busca estaciones de Europa y hemisferio sur.
									Comprueba nuestra cobertura en el{" "}
									<Link to="/resortsmap">
										<u>mapa de estaciones.</u>
									</Link>
								</p>
							</div>
							<div className="right">
								<small>Sponsored by</small>
								<img src="/img/landrover.png" alt="" />
							</div>
						</MapStationWrapper>

						{!isMobile768 && (
							<MessageBox
								text="¿Tienes dudas de cómo funciona Fresh Snow?"
								buttonText="Ayuda & FAQs"
								buttonUrl="/help"
							/>
						)}
					</PageContent>
				</PageContentWrapper>

				{/* <PageContentWrapper layout="dark">
					<PageContent width="1050px">
						{bootLoaded && ads && (
							<Slider
								layout="dark"
								type="ads"
								items={ads}
								text=""
								title="Be Smart Snow"
								buttonText=""
								buttonUrl=""
							/>
						)}
					</PageContent>
				</PageContentWrapper> */}

				{!isMobile768 && (
					<PageContentWrapper>
						<PageContent width="1050px">
							{bootLoaded && lugaresHome && (
								<Grid
									layout="light"
									items={lugaresHome}
									title="Descubre nuevos lugares"
									text="Sorpréndete con los lugares y estaciones que te quedan por conocer, descubre los mejores secretos de la montaña y disfruta al máximo tu experiencia en la nieve."
									buttonText="Ver todos los lugares"
									buttonUrl="/locations"
								/>
							)}
						</PageContent>
					</PageContentWrapper>
				)}

				{!isMobile768 && (
					<article className={styles.ourTech}>
						<img
							className={styles.mobileImg}
							src="/home/bg/bg-home-mobile.jpg"
							alt="Nuestra Tecnología"
						/>
						<div className={styles.ourTechWrapper}>
							<div className={styles.details}>
								<div className={styles.video}>
									<iframe
										src="https://player.vimeo.com/video/443071720?autoplay=1&loop=1&autopause=0&muted=1&background=1"
										width="354"
										height="540"
										title="Nuestra Tecnología"
										frameBorder="0"
										allow="autoplay"
									></iframe>
									{/* <ul>
									<li className={styles.apple}>
										<a
											href="https://apps.apple.com/us/app/fresh-snow/id1280773131"
											target="_blank"
											rel="noreferrer"
										>
											<img src="/home/appstore.png" />
										</a>
									</li>
									<li className={styles.google}>
										<a
											href="https://play.google.com/store/apps/details?id=com.crowdland.freshsnow&hl=es"
											target="_blank"
											rel="noreferrer"
										>
											<img src="/home/gplaystore.png" />
										</a>
									</li>
								</ul> */}
								</div>
								<div className={styles.text}>
									<h3>Nuestra Tecnología</h3>
									<p>
										Algoritmos de predicción de alta montaña diseñados
										por esquiadores, inteligencia aumentada alimentada
										por feedbacks de usuarios, pronóstico extendido a
										14 días en tres alturas y geolocalización de
										estaciones para ordenar resultados por cercanía y
										nieve.
									</p>
									<CustomLink to="/technology">
										<p>Conoce más</p>
									</CustomLink>
								</div>
								<img
									className={styles.device}
									src="/home/devices-new.png"
									alt="Nuestra Tecnología - Devices"
								/>
							</div>
						</div>
					</article>
				)}

				{!isMobile768 && false && (
					<PageContentWrapper className={styles.snowgods}>
						<PageContent width="1050px">
							<Slider
								layout="light"
								type="snowgod"
								items={snowGods}
								title="Nuestros Snowgods"
								text="Son nuestros usuarios expertos en pie de pistas los que van a darte la información más detallada para ayudarte a que tomes la mejor decisión."
								buttonText="Ver todos los Snowgods"
								buttonUrl="/snowgods"
							/>
						</PageContent>
					</PageContentWrapper>
				)}

				{news.length > 0 && (
					<PageContentWrapper className={styles.snowgods}>
						<PageContent width="1050px">
							<Slider
								layout="light"
								type="news"
								items={news}
								title="Actualidad"
								text="Fresh Snow y Lugares de nieve se unen para traerte toda la actualidad relacionada a la nieve en Pirineos y el resto del mundo."
								buttonText="Ver todas"
								buttonUrl="https://www.lugaresdenieve.com/"
								isExternal={true}
							/>
						</PageContent>
					</PageContentWrapper>
				)}
			</section>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	const { isMobile768 } = state.app.global;
	const {
		items: favoritos,
		// loading: favLoading,
		loadingSuccess: favloadingSuccess,
	} = state.favoritos;
	return {
		authResolved: state.auth.resolved,
		loggedIn: state.auth.loggedIn,
		bootLoaded: state.app.boot.success,
		ads:
			state.app.boot.data && state.app.boot.data.ads
				? state.app.boot.data.ads
				: null,
		lugaresHome:
			state.app.boot.data && state.app.boot.data.lugares_home
				? state.app.boot.data.lugares_home
				: null,
		favoritos,
		favloadingSuccess,
		isMobile768,
	};
};

const mapDispatchToProps = {
	appModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
