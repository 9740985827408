import styled from "styled-components";
import { colors, mediaQueryMax } from "../vars";
import { PageContent, PageContentWrapper } from "../pageCommon";

export const MainHomeWrapper = styled.div`
	background-color: ${colors.black00};
	@media ${mediaQueryMax.md} {
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		transition: height 0.3s ease-in-out;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
	}

	${PageContentWrapper} {
		overflow: visible;
		height: 400px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: flex-end;

		${PageContent} {
			width: 100%;
			box-sizing: border-box;
		}

		h2 {
			max-width: 50%;
			margin-bottom: 50px;
			margin-left: auto;
			display: flex;
			justify-content: flex-end;
		}
		@media ${mediaQueryMax.md} {
			height: auto;
			flex: 1;
			padding-bottom: 35px;
			h2 {
				max-width: 100%;
				font-weight: 500;
				color: ${colors.white};
				margin: 0 0 25px;
				display: block;
			}
		}
	}
`;

export const MapStationWrapper = styled.div`
	padding: 50px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.left {
		flex: 0 0 50%;
		max-width: 50%;

		h4 {
			color: ${colors.white};
			font-size: 25px;
			line-height: 32px;
			letter-spacing: -1px;
			margin-bottom: 5px;
		}

		p {
			font-size: 20px;
			line-height: 1.25;
			color: #7a7a7a;

			a {
				color: #b4b4b4;

				u {
					text-decoration: none;
					position: relative;

					&:after {
						content: "";
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						height: 1px;
						background-color: #b4b4b4;
					}
				}
			}
		}
	}

	.right {
		flex: 0 0 30%;
		max-width: 30%;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		small {
			color: ${colors.grayD8};
			font-size: 13px;
			line-height: 22px;
			margin-right: 15px;
		}
	}

	@media ${mediaQueryMax.md} {
		padding: 0;
		align-items: center;
		.left {
			p {
				font-size: 16px;
			}
		}
		.right {
			flex-direction: column;
			gap: 10px;
		}
	}
	@media ${mediaQueryMax.xs2} {
		gap: 10px;
		.left,
		.right {
			flex: 0 0 100%;
			max-width: 100%;
		}
		.right {
			flex-direction: row;
			justify-content: space-between;
		}
	}
`;
